<style>
  div.root {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  main {
    text-align: center;
    padding: 1em;
    margin: 0;
    flex-grow: 1;
    padding: 0 80px;
    transition: all 0.16s ease-in-out;
  }

  @media (max-width: 840px) {
    main {
      padding: 0 40px;
    }
  }

  @media (max-width: 600px) {
    main {
      padding: 0 20px;
    }
  }

  main > * {
    height: 100%;
  }

  @media (min-width: 600px) {
    main {
      max-width: none;
      margin: 0 auto;
    }
  }
</style>

<script>
  import MVPContainer from "./container/MVPContainer.svelte";
  import ProvinceProgressContainer from "./container/ProvinceProgressContainer.svelte";
  import FooterContainer from "./container/FooterContainer.svelte";
  import Confettis from "./components/Confettis.svelte";

</script>

<div class="root">
  <Confettis />
  <main class="container">
    <div class="columns">
      <div class="column col-7 col-md-12">
        <ProvinceProgressContainer />
      </div>
      <div class="column col-5 col-md-12">
        <MVPContainer />
      </div>
    </div>
  </main>

  <FooterContainer />
</div>
