<style>
  div.root {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  main {
    text-align: center;
    padding: 1em;
    margin: 0;
    flex-grow: 1;
    padding: 0 80px;
    transition: all 0.16s ease-in-out;
  }

  @media (max-width: 840px) {
    main {
      padding: 0 40px;
    }
  }

  @media (max-width: 600px) {
    main {
      padding: 0 20px;
    }
  }

  main > * {
    height: 100%;
  }

  @media (min-width: 600px) {
    main {
      max-width: none;
      margin: 0 auto;
    }
  }
</style>

<script>
  import RankingContainer from "./container/RankingContainer.svelte";
</script>

<div class="root">
  <main class="container">
    <RankingContainer />
  </main>
</div>
